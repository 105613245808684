<template>
    <div class="mypage_item_history_wrap">
        <table class="my_history_table" width="100%" border="0" cellspacing="0" cellpadding="0"
               style="table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="90px"/>
                <col width="135px"/>
                <col width="190px"/>
                <col width="130px"/>
                <col width="105px"/>
                <col width="130px"/>
                <col width="155px"/>
                <col width="140px"/>
                <col width="120px"/>
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{ $t('image') }}</th>
                <th colspan="1">{{ $t('subject') }}</th>
                <th colspan="1">{{ $t('status') }}</th>
                <th colspan="1">{{ $t('price') }}</th>
                <th colspan="1">{{ $t('licenseOption') }}</th>
                <th colspan="1">{{ $t('residualDown') }}</th>
                <th colspan="1">{{ $t('history_title') }}</th>
                <th colspan="1">{{ $t('dateTime') }}</th>
            </tr>
            <tr v-for="(data,index) in itemData" :key="`item${index}`">
                <td class="cursor" @click="movePage(data)">{{ tableIdx(index) }}</td>  <!-- No -->
                <td class="cursor" @click="movePage(data)"><img :src="returnThumbnail(data)" width="78" height="49"/>
                </td>
                <td class="cursor t_subject" @click="movePage(data)">{{ data.i_name }}</td>  <!-- 아이템명 -->

                <td class="cursor" @click="movePage(data)">{{ returnStatus(data) }}</td>  <!-- 상태 -->

                <td class="cursor" @click="movePage(data)" v-if="checkData(data.ih_price)">{{ data.ih_price }}</td>
                <!-- 금액 -->
                <td class="cursor" @click="movePage(data)" v-else>-</td>
                <!-- 라이센스 옵션 -->
                <td class="cursor" @click="movePage(data)" v-if="checkData(data.ih_option)">{{ data.ih_option }}</td>
                <td class="cursor" @click="movePage(data)" v-else>-</td>


                <!-- 잔여다운 차후진행 -->
                <td v-if="checkLicense(data) === 'download'">
                    <button class="endDate" @click="downloadLicense(data)">
                        <!--                        {{ returnItemDateTime(data) }}<br/>-->
                        {{ returnDateFormat(data.license_day, 'YY-MM-DD') }} <br>
                        {{ returnLicenseCount(data) }}{{ $t('times') }}
                    </button>
                </td>
                <td v-else-if="checkLicense(data) === 'end'">
                    {{ $t('endDate') }}
                </td>
                <td v-else>-</td>

                <!-- 거래내역서 버튼 -->
                <td v-if="checkHistory(data)">
                    <button @click="moveHistory(data.m_idx)">{{ $t('history_title') }}</button>
                </td>
                <td v-else>-</td>

                <td class="cursor" @click="movePage(data)">{{ data.created_at }}</td>  <!-- 일시 -->
            </tr>
        </table>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import fileMixins from "@/mixins/fileMixins";
import alertMixins from "@/mixins/alertMixins";
import dateMixins from "@/mixins/dateMixins";
import EventBus from "@/utils/event-bus";

let timeInterval = null;
export default {
    name: "ItemListMyItemHistoryLayout",
    mixins: [imageOption, fileMixins, alertMixins, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {
            historyLayout: 6
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
    },
    beforeDestroy() {
        clearInterval(timeInterval)
    },
    destroyed() {
    },
    computed: {},
    methods: {
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        movePage(data) {
            console.log(data);
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
            } else if (data.ih_status === 'cancel' || data.i_use === 0) {
                this.createAlert({
                    content: this.$t('delete_item_error_msg'),
                    btnText: this.$t('yes'),
                    hide: () => {
                        return
                    }
                })
            } else {
                this.$router.push(`/item/${data.i_sku}`);
            }
        },
        moveHistory(url) {
            this.$router.push(`/mypage/item/${url}/history`)
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'png')}`;
            }
        },
        checkData(data) {
            return !util.isEmpty(data);
        },
        checkHistory(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.m_idx) && data.i_use !== 0 && data.ih_status !== 'create') {
                return true
            }
            return false
        },
        returnStatus(data) {
            //라이센스 관련 차후 데이터 들어온후 진행 필요
            if (data.ih_status === 'create') {
                return this.$t('enroll')
            } else if (data.ih_status === 'provideBuy') {
                return this.$t('ownership_buy')
            } else if (data.ih_status === 'provideSell') {
                return this.$t('ownership_sell')
            } else if (data.ih_status === 'curationRequest') {
                return this.$t('c_request')
            } else if (data.ih_status === 'curationCancel') {
                return this.$t('c_request_cancel')
            } else if (data.ih_status === 'licenseBuy') {
                return this.$t('licenseBuy')
            } else if (data.ih_status === 'licenseSell') {
                return this.$t('licenseSell')
            } else if (data.ih_status === 'cancel') {
                return this.$t('confirm_del')
            }
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },

        getDifferenceTime(endTime, currentTime) {
            let current = this.$moment(currentTime)
            let end = this.$moment(endTime)
            if (current > end) {
                return 0;
            }
            let diff = current.diff(end, 'second')

            let hour = Math.abs(parseInt(diff / 3600, 10))
            let min = Math.abs(parseInt((diff % 3600) / 60, 10))
            let sec = Math.abs(parseInt((diff % 3600) % 60, 10));

            hour = hour < 10 ? `0${hour}` : hour
            min = min < 10 ? `0${min}` : min
            sec = sec < 10 ? `0${sec}` : sec
            return `${hour} : ${min} : ${sec}`
        },
        checkLicense(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            let current = this.$moment(currentTime);
            let end = this.$moment(data.license_day);
            if (data.ih_status === 'licenseBuy' && current < end && data.i_ues !== 0) {
                return 'download'
            } else if (data.ig_status === 'licenseBuy' && current > end && data.i_ues !== 0) {
                return 'end'
            }
        },
        returnLicenseCount(data) {
            if (data.License === 0) {
                return 3
            } else if (data.License === 1) {
                return 2
            } else if (data.License === 2) {
                return 1
            } else {
                return 0
            }
        },
        downloadLicense(data) {
            if (data.License < 3) {
                this.getFileJwtToken('license', data.m_idx).then(res => {
                    EventBus.$emit('getItemList');
                });
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
